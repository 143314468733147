<template>
<div>

    <v-container fluid class="down-top-padding">
        <div class="d-flex justify-space-between">
                    <v-btn color="red" dark @click="goBack">Retour</v-btn>
                </div>
            
        <v-card>
               
            <v-card-text class="pa-2">
                <v-divider></v-divider>
                <v-card>
                    <v-card-title class="pa-4 info">
                        <span class="title white--text">{{ detail_Formulaire }}</span>
                    </v-card-title>

                    <v-card-text>

                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-row>

                                        <v-col cols="12" sm="6">
                                            <v-select :items="listcommune" @change="fuseSearchcommune" outlined hide-details item-value="id" v-model="selectcommune" label="Commune *">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="null">
                                            <v-select :items="listarrondissement" @change="fuseSearcharrondissement" outlined hide-details item-value="id" v-model="selectarrondissement" label="Arrondissement *">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>

                                            </v-select>
                                        </v-col>

                                        <v-col cols="12" sm="6" v-if="null">
                                            <v-select :items="listvillage" outlined hide-details item-value="id" v-model="selectvillage" label="Village/Ville *">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.libelle }}
                                                </template>

                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-btn color="error" dark @click="dialog2=false">Cancel</v-btn>
                        <v-btn color="success" dark @click="save_zone">{{ text_btn }}</v-btn>
                                        </v-col>
                                        
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-data-table :headers="headers" :items="list" sort-by="id" class="contact-listing-app">
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <div class="d-flex">

                                                <v-icon large class="mb-2 mr-2 info--text" @click="getitem(item)">mdi-pencil</v-icon>
                                                <v-icon large class="mb-2 mr-2 error--text" @click="deletItem(item)">mdi-delete</v-icon>
                                            </div>
                                        </template>
                                        <template v-slot:[`item.status_compte`]="{ item }">
                                            <v-btn v-if="item.status_compte==1" color="success" dark class="mb-2 text-capitalize" @click="changeStatus(item)">
                                                Actif</v-btn>
                                            <v-btn v-else color="error" dark class="mb-2 text-capitalize" @click="changeStatus(item)">
                                                Inactif</v-btn>
                                        </template>

                                    </v-data-table>
                                </v-col>

                            </v-row>

                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        
                    </v-card-actions>
                </v-card>

            </v-card-text>
        </v-card>
    </v-container>
</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "ProjectList",
    props: {
        id: {
            type: String,
            default: null
        },
        libelle_atda: {
            type: String,
            default: null
        },
    },
    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        //detail_Formulaire: "ZONE INTERVENTION",

        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '4%'

            },
            {
                text: "Id",
                align: "start",
                sortable: false,
                value: "id",
                divider: true,
                width: '5%'
            },
           
            {
                text: "Commune",
                align: "start",
                sortable: false,
                value: "commune.libelle",
                divider: true,
                width: '150px'
            },

            

        ],
        show1: true,

        editedItem: {
            id: '',
           
        },

        list: [],
        listdirection: [],
        listcommune: [],
        listarrondissement: [],
        listvillage: [],

        listarrondissement2: [],
        listvillage2: [],
        listprofil: [],

        etat: false,
        etat2: false,
        selectcommune: '',
        selectarrondissement: '',
        selectvillage: '',
        selectdirection: '',
        selectprofil: '',
        id_associer: '',
        text_btn: 'Enregistrer',

    }),

    watch: {

    },

    created() {
        //this.readAll_geographie();
        //this.readAll_Survey();
        this.Affiche_title();
        this.readAll();
        this.readAll_globale_localisation();
      
        // this.readAll_Export_Excel();
    },

    methods: {

        Affiche_title(){
            //this.detail_Formulaire = this.$route.params.libelle_atda;
        },

        readAll: async function () {
            let fd = new FormData();
            fd.append('id_atda', this.$route.query.id_atda);

            const data = await api.createUpdatedata('admin-backoffice/all-associe-pole', fd);
            this.list = data;

        },
        goBack() {
            this.$router.go(-1);
        },

        readAll_globale_localisation: async function () {
            let fd = new FormData();
            fd.append('type_direction', '');

            const data = await api.readAll('admin-backoffice/globale-localisation');
            this.listdirection = data.ddaep;
            this.listcommune = data.commune;
            this.listarrondissement2 = data.arrondissement;
            this.listvillage2 = data.village;

            const data2 = await api.createUpdatedata('admin-backoffice/all-filtre', fd);
            this.listannee= data2.annee;
            this.listmois= data2.mois;
            this.listtrimestre= data2.trimestre;


        },


        save_zone: async function () {
            let fd = new FormData();
            fd.append('id_commune', this.selectcommune);
            fd.append('id_pole', this.$route.query.id_atda);
            if (this.etat == false) {
                const data = await api.createUpdatedata('admin-backoffice/add-associe-pole', fd);
                if (data.status == true) {
                    this.showAlert('Enregistrement effectué avec succès', 'success')
                    this.clearitem();

                } else if (data.status == false) {
                    this.showAlert(data.message, 'error')
                }
            } else {
                const data = await api.createUpdatedata('admin-backoffice/update-associe-pole/' + this.id_associer, fd);
                if (data.status == true) {
                    this.showAlert('Modification effectuée avec succès', 'success')
                    this.clearitem();
                    this.dialog = false;

                } else if (data.status == false) {
                    this.showAlert('Modification non effectuée', 'error')
                }
            }
            this.readAll();

        },

        deletItem: async function (item) {

            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    const data = await api.readAll('admin-backoffice/delete-associe-pole/' + item.id);
                    if (data.succes.status == true) {
                        this.showAlert('Suppression effectuée avec succès', 'success')
                        this.clearitem();
                        this.dialog = false;

                    } else if (data.succes.status == false) {
                        this.showAlert('Suppression non effectuée', 'error')
                    }
                    this.readAll();

                }
            })

        },

        clearitem() {
            this.etat = false;
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        getitem(item) {
            console.log(item);
            this.text_btn= 'Modifier';

            this.etat = true;
            this.id_associer=item.id;
            this.selectcommune = item.commune.id;
        },

        fetchData() {
            this.readAll();
        },

        fuseSearchcommune: function () {
            this.listarrondissement = this.listarrondissement2.filter(this.parcourre_arrondissement);
            this.listvillage = [];

        },

        fuseSearcharrondissement: function () {
            this.listvillage = this.listvillage2.filter(this.parcourre_village);
        },

        parcourre_type_direction: function (obj) {
            if (obj.type_direction == 'DDAEP-AGENT') {
                return true;
            } else {
                return false;
            }
        },
        parcourre_arrondissement: function (obj) {
            if (obj.id_communes == this.selectcommune) {
                return true;
            } else {
                return false;
            }
        },

        parcourre_village: function (obj) {
            if (obj.id_arrondissements == this.selectarrondissement) {
                return true;
            } else {
                return false;
            }
        },

    },
};
</script>
